import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs = (props) => {
  const {message, setMessage} = props;
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    // Validation logic
    const name = form.current.user_name.value;
    const email = form.current.user_email.value;
    const subject = form.current.subject.value;
    const phone = form.current.phone.value;
    const contactMethod = form.current.contact_method.value;
    // const servicesInterested = form.current.services_interested.value;
    const message = form.current.message.value;
  
    if (!name || !email || !subject || !phone || !contactMethod || !message) {
      // Display an error message or prevent the form submission
      alert('Please fill in all required fields.');
      return;
    }
  
    // If all fields are filled, proceed with sending the email
    emailjs.sendForm('service_zv2b8a7', 'template_bsopu47', form.current, '__DmOptKYHY6Ge8CY')
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        alert('Your message was successfully sent!');
      })
      .catch((error) => {
        console.log(error.text);
      });
  };
  

  return (
<div className="w-full max-w-3xl mx-auto mt-16">
  <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-8">Contact Us</h2>
  <form ref={form} onSubmit={sendEmail} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col md:flex-row md:flex-wrap">
  <div className="mb-4 w-full md:w-1/2 md:pr-2">
      <label className="block text-gray-700 text-sm font-bold mb-2">Name <span className='text-red-600'>*</span></label>
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="user_name" />
    </div>
    <div className="mb-4 w-full md:w-1/2 md:pl-2">
      <label className="block text-gray-700 text-sm font-bold mb-2">Email <span className='text-red-600'>*</span></label>
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="email" name="user_email" />
    </div>
    <div className="mb-4 w-full md:w-1/2 md:pr-2">
      <label className="block text-gray-700 text-sm font-bold mb-2">Subject <span className='text-red-600'>*</span></label>
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="subject" />
    </div>
    <div className="mb-4 w-full md:w-1/2 md:pl-2">
      <label className="block text-gray-700 text-sm font-bold mb-2">Phone <span className='text-red-600'>*</span></label>
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="phone" />
    </div>
    <div className="mb-4 w-full md:w-1/2 md:pr-2">
      <label className="block text-gray-700 text-sm font-bold mb-2">Company</label>
      <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="company" />
    </div>
    <div className="mb-4 w-full md:w-1/2 md:pl-2">
      <label className="block text-gray-700 text-sm font-bold mb-2">Preferred Contact Method <span className='text-red-600'>*</span></label>
      <select defaultValue={"select"} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="contact_method">
        <option disabled value="select">--Select an option--</option>
        <option value="email">Email</option>
        <option value="phone">Phone</option>
      </select>
    </div>
    <div className="mb-4 w-full md:w-1/2 md:pr-2">
      <label className="block text-gray-700 text-sm font-bold mb-2">Preferred Contact Time</label>
      <select defaultValue={"select"} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="contact_time">
        <option disabled value="select">--Select an option--</option>
        <option value="morning">Morning</option>
        <option value="afternoon">Afternoon</option>
        <option value="evening">Evening</option>
      </select>
    </div>
    <div className="mb-4 w-full md:w-1/2 md:pl-2">
      <label className="block text-gray-700 text-sm font-bold mb-2">Phone Type</label>
      <select defaultValue={"select"} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="phone_type">
        <option disabled value="select">--Select an option--</option>
        <option value="home">Home</option>
        <option value="work">Work</option>
        <option value="mobile">Mobile</option>
      </select>
    </div>
    <div className="mb-4 w-full">
      <label className="block text-gray-700 text-sm font-bold mb-2">Message <span className='text-red-600'>*</span></label>
      <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
    </div>
    <div className="mb-4 w-full">
      <label className="block text-gray-700 text-sm font-bold mb-2">How did you hear about us?</label>
      <select defaultValue={"select"} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="hear_about">
        <option disabled value="select">--Select an option--</option>
        <option value="google">Google</option>
        <option value="friend">Friend</option>
        <option value="other">Other</option>
      </select>
    </div>
    {/* <div className="mb-4">
      <div className="g-recaptcha" data-sitekey="your_site_key"></div>
    </div> */}
    <div className="mb-6">
      <input className="bg-indigo-600 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" value="Send" />
    </div>
  </form>
</div>


  );
};